/*/* You can add global styles to this file, and also import other style files */
/*html {
  font-family: "Asap","Helvetica Neue","Helvetica","Arial","sans-serif";
}
body {
  font-family: "Asap","Helvetica Neue","Helvetica","Arial","sans-serif";
}*/
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "~bootstrap/dist/css/bootstrap.css";
mat-dialog-container#modal-component {
  border: 3px solid #262626;
  border-radius: 11px;
  background-color: white;
}
/*@media (max-width: @screen-xs-max) {
  
}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
 
}

@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
 
}

@media (min-width: @screen-lg-min) {
  
}*/
html,body
{
    height:100%;
}
.social-icon {
  margin: 20px 0;
  padding: 0;
  display: block;
  text-align:center;
}

  .social-icon li {
    display: inline-block;
   
  }
    .social-icon li a:hover {
      background-color: #000;
    }
    .social-icon li a {
    
      text-align: center;
      padding: 12px;
    
      color: white;
      font-size: 10px;
    
    }

      .social-icon li a:hover {
        border-color: #cccccc;
      }
.icon-bar li {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}
.facebook {
  background: #3B5998;
  color: white;
}

.twitter {
  background: #55ACEE;
  color: white;
}

.google {
  background: #dd4b39;
  color: white;
}

.linkedin {
  background: #007bb5;
  color: white;
}

.youtube {
  background: #bb0000;
  color: white;
}
.container-margin{
    margin-left:1px;
    margin-right:1px;
}
.container-padding{
    padding-left:0px;
}
.screen-width{
    max-width:1300px;
}
.carousel-inner span {
  display: none;
}
 button span {
  display: none;
}
button.carousel-control-next.ng-star-inserted {
  border: 0px;
  background-color: #9F4CEC;
}

button.carousel-control-prev.ng-star-inserted {
  border: 0px;
  background-color: #9F4CEC;
}
