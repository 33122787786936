
.footer_bg_1 {
  background-image: url(assets/img/footer_bg.png);
}

.footer {
  background-repeat: no-repeat;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

  .footer .footer_top {
    padding-top: 70px;
    padding-bottom: 129px;
  }

@media (max-width: 767px) {

  .footer .footer_top {
    padding-top: 60px;
    padding-bottom: 30px;
  }
}

@media (max-width: 767px) {

  .footer .footer_top .footer_widget {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  .footer .footer_top .footer_widget {
    margin-bottom: 30px;
  }
}


.footer .footer_top .footer_widget .footer_title {
  font-size: 22px;
  font-weight: 400;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 40px;
}

@media (max-width: 767px) {

  .footer .footer_top .footer_widget .footer_title {
    margin-bottom: 20px;
  }
}


.footer .footer_top .footer_widget {
  font-size: 22px;
  font-weight: 400;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 40px;
}

@media (max-width: 767px) {

  .footer .footer_top .footer_widget {
    margin-bottom: 20px;
  }
}


.footer .footer_top .footer_widget p {
  color: #C7C7C7;
}


  .footer .footer_top .footer_widget p.footer_text {
    font-size: 16px;
    color: #B2B2B2;
    margin-bottom: 23px;
    font-weight: 400;
    line-height: 28px;
  }


    .footer .footer_top .footer_widget p.footer_text a.domain {
      color: #B2B2B2;
      font-weight: 400;
    }


      .footer .footer_top .footer_widget p.footer_text a.domain:hover {
        color: #FDAE5C;
        border-bottom: 1px solid #FDAE5C;
      }


    .footer .footer_top .footer_widget p.footer_text.doanar a {
      font-weight: 500;
      color: #B2B2B2;
    }


      .footer .footer_top .footer_widget p.footer_text.doanar a:hover {
        color: #FDAE5C;
        border-bottom: 1px solid #FDAE5C;
      }


      .footer .footer_top .footer_widget p.footer_text.doanar a.first {
        margin-bottom: 10px;
      }


.footer .footer_top .footer_widget ul li a {
  font-size: 16px;
  color: #BABABA;
  line-height: 42px;
}


  .footer .footer_top .footer_widget ul li a:hover {
    color: #FDAE5C;
  }


.footer .footer_top .footer_widget .newsletter_form {
  position: relative;
  margin-bottom: 20px;
}


  .footer .footer_top .footer_widget .newsletter_form input {
    width: 100%;
    height: 45px;
    background: #fff;
    padding-left: 20px;
    font-size: 16px;
    color: #000;
    border: none;
  }


.footer .footer_top .footer_widget {
  font-size: 16px;
  color: #919191;
}


  .footer .footer_top .footer_widget .newsletter_form button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    border: none;
    font-size: 14px;
    color: #fff;
    background: #FDAE5C;
    padding: 10px;
    padding: 0 22px;
    cursor: pointer;
  }


  .footer .footer_top .footer_widget .newsletter_text {
    font-size: 16px;
    color: #BABABA;
  }


.footer .copy-right_text {
  padding-bottom: 30px;
}


  .footer .copy-right_text .footer_border {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 30px;
  }


  .footer .copy-right_text .copy_right {
    font-size: 16px;
    color: #919191;
    margin-bottom: 0;
    font-weight: 400;
  }

@media (max-width: 767px) {

  .footer .copy-right_text .copy_right {
    font-size: 14px;
  }
}


.footer .copy-right_text .copy_right a {
  color: #FDAE5C;
}

.footer .socail_links {
  margin-top: 47px;
}

@media (max-width: 767px) {

  .footer .socail_links {
    margin-top: 30px;
  }
}


.footer .socail_links ul li {
  display: inline-block;
}


  .footer .socail_links ul li a {
    font-size: 18px;
    color: #C3B2F0;
    line-height: 42px;
    width: 50px;
    height: 50px;
    display: inline-block;
    text-align: center;
    background: #8565E2;
    border-radius: 3px;
    line-height: 50px !important;
    margin-right: 7px;
  }

    .footer .socail_links ul li a:hover {
      color: #fff !important;
    }


.breadcam_bg {
  background-image: url(assets/img/bradcam.png);
}


.breadcam_bg_2 {
  background-image: url(assets/img/bradcam2.png);
}


.bradcam_area {
  padding: 170px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  text-align: center;
  padding: 269px 0 159px 0;
}

@media (max-width: 767px) {

  .bradcam_area {
    padding: 150px 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  .bradcam_area {
    padding: 150px 0;
  }
}


.bradcam_area h3 {
  font-size: 60px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 0;
  text-transform: capitalize;
}

@media (max-width: 767px) {

  .bradcam_area h3 {
    font-size: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  .bradcam_area h3 {
    font-size: 40px;
  }
}


.bradcam_area2 {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 220px 0 180px 0;
}

@media (max-width: 767px) {

  .bradcam_area2 {
    padding: 150px 0;
  }
}


.bradcam_area2 .breadcam_text h3 {
  font-size: 42px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 50px;
}

@media (max-width: 767px) {

  .bradcam_area2 .breadcam_text h3 {
    margin-bottom: 20px;
    font-size: 25px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  .bradcam_area2 .breadcam_text h3 {
    margin-bottom: 30px;
    font-size: 30px;
  }
}


.bradcam_area2 {
  padding: 0 50px;
}

@media (max-width: 767px) {

  .bradcam_area2 {
    padding: 0;
  }
}


.bradcam_area2 {
  width: 100%;
  position: relative;
}


.bradcam_area2 {
  width: 100%;
  height: 60px;
  background: #fff;
  font-size: 16px;
  color: #000;
  font-weight: 400;
  border: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 20px;
  width: 70%;
  float: left;
}

@media (max-width: 767px) {

  .bradcam_area2 {
    height: 50px;
  }
}



.bradcam_area2 {
  background: #670080;
  width: 30%;
  float: right;
  height: 60px;
  border: none;
  color: #fff;
  font-weight: 400;
  text-transform: capitalize;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  cursor: pointer;
}

@media (max-width: 767px) {

  .bradcam_area2 {
    height: 50px;
  }
}

[class^="ti-"], [class*=" ti-"] {
  font-family: 'themify';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.linkcolor {
  color: white;
}
